// tslint:disable:no-http-string
import { Link } from 'gatsby';
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { SocialLink, SearchWidgetIcon, SiteNavMenu, SiteNavMenuItem } from '../../styles/shared';

import algoliasearch from 'algoliasearch';
import PostPreview from '../search/post-preview';
import { InstantSearch, Hits, connectStateResults, PoweredBy, connectSearchBox } from 'react-instantsearch-dom';

import SiteNavLogo from './SiteNavLogo';
import config from '../../website-config';
import Facebook from '../icons/facebook';
import Twitter from '../icons/twitter';
import LinkedIn from '../icons/linked-in';
import YouTube from '../icons/youtube';
import SearchIcon from '../icons/search';
import Spiceworks from '../icons/spiceworks';
import Search from '../../pages/search';
import DarkModeToggle from '../darkmode/DarkModeToggle';
import { useState } from 'react';

const HomeNavRaise = css`
  @media (min-width: 900px) {
    position: relative;
    top: -70px;
  }
`;

const SiteNavStyles = css`
  position: relative;
  z-index: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
  height: 50px;
  font-size: 1.2rem;

  @media (max-width: 1023px) {
    .ais-SearchBox-input {
      margin-right: 10px;
    }
    h3 {
      font-size: 1.8rem;
    }
    .ais-SearchBox-resetIcon {
      right: 70%;
    }
    .ais-Hits-list {
      width: 100%;
      left: 0;
    }
  }
  @media (min-width: 1024px) {
    h3 {
      font-size: 2.8rem;
    }
    .ais-SearchBox-resetIcon {
      right: 44.5rem;
    }
    .ais-Hits-list {
      width: 25%;
      left: 75%;
    }
  }

  .ais-Hits-list {
    position: fixed;
    margin-top: 32px;
    background: rgba(0, 25, 40, 1);
    padding: 0 20px 0 20px;
    list-style: none;
  }
  .ais-Hits-item {
    font-family: 
  }
  .ais-Hits-item a {
    color: #FFFFFF;
    word-break: break-word;
    text-decoration: none;
    transition: background 500ms ease;
    background-image: linear-gradient(transparent 50%, var(--textLink) 50%);
    background-size: 2px;
    background-size: auto 175%;
  }
  .ais-Hits-item a:hover {
    background-position-y: 110%;
  }
  .ais-SearchBox-input, .site-nav-instant-search-wrapper input {
    border: 1px solid var(--textLink);
    padding: 10px 14px;
    margin-right: 5px;
    text-align: left; 
    letter-spacing: 1px;
    background: transparent;
    border-radius: 200px;
    transition: 0.07s;
    ::-webkit-input-placeholder {
      color: var(--textLink);
    }
    :hover {
      border: 2px solid var(--textLink);
    }
    
  }
  .ais-SearchBox-submit {
    display: none;
  }
  .ais-SearchBox-resetIcon {
    // display: flex;
    // position: fixed;
    // top: 2.75rem;
    justify-content: center;
    align-items: center;
    margin: 0;
    color: #3eecac;
    opacity: 0.8;
    z-index: -1;
  
    svg {
      height: 1rem;
      fill: #3eecac;
    }
  }
  .ais-SearchBox-resetIcon path {
    fill: #FFFFFF;
  }
  .ais-Highlight-highlighted {
    background-color: var(--linkText);
    color: #3e7eec;
  }
  .ais-PoweredBy {
    position: fixed;
    bottom: 30px;
    left: 30px;
    background: var(--burgerBg);
    padding: 5px;
    display: none;
  }
  .ais-PoweredBy-text {
    font-family: monospace;
    margin-right: 5px;
    color: var(--textNormal);
  }
  .ais-PoweredBy-logo {
    height: 1.2em;
    width: auto;
    display: inline-block;
    vertical-align: sub;
  }
  .no-results {
    position: fixed;
    background: rgba(0, 25, 40, 0.97);
    margin: 49px 0 0 0;
    padding: 5px;
  }
  .no-results-query {
    vertical-align: initial;
    background: #ec3e5e;
  }
  h3 {
    margin: 0.5em 0 0.2em 0;
    font-weight: 700;
    font-family: "Lato";
  }
  li p {
    font-family: "Rubik";
  }
  .ais-SearchBox-reset {
    margin-right: 1rem;
  }
  .ais-SearchBox-form {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const SiteNavCenter = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 374px;
  @media(max-width: 700px) {
    padding-bottom: 12px;
  }

  .site-nav-logo {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const SiteNavLeft = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
  letter-spacing: 0.4px;
  white-space: nowrap;

  -ms-overflow-scrolling: touch;

  @media (max-width: 700px) {
    margin-right: 0;
    white-space: normal;
    text-align: center;
  }
`;

const NavStyles = css`
  display: flex;
  margin: 0 0 0 -12px;
  padding: 0;
  list-style: none;

  li {
    display: block;
    margin: auto;
    padding: 0;
    text-transform: uppercase;
  }

  li a {
    display: block;
    margin: 0;
    padding: 10px 12px;
    color: #fff;
    opacity: 0.8;
  }

  li a:hover {
    text-decoration: none;
    opacity: 1;
  }

  @media (max-width: 700px) {
    margin: 0;
  }

`;

const SiteNavRight = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
`;

const SocialLinks = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const PostCTAbutton = styled.button`
  display: block;
  background-color: #60c659;
  border-radius: 200px;
  color: #1a1a1a;
  padding: 7px 20px;
  margin-left: 5px;
  font-weight: 600;
  font-family: 'Rubik';
  letter-spacing: 2px;
  transition: all .15s ease-in-out;
  text-transform: uppercase;

  :hover {
    color: #ffffff;
  }

@media (max-width: 1170px) {
  // margin: .5rem 0 0 .1rem; 
}

@media (max-width: 800px) {
  // margin: .5rem 0 0 .1rem; 
}
@media (max-width: 500px) {
  // margin: .5rem 0 0 .1rem; 
}
`;

const HeaderDarkModeToggle = css`
  margin-left: 10px;
  max-height: 100%;
  @media (max-width: 1571px){
    display: none;
  }
`;

const CustomSearchBox_icon = css`
  position: absolute;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;
  right: 1.8rem;
  top: 0;
  width: 2rem;
  height: 3.7rem;
`;

interface SiteNavProps {
  isHome?: boolean;
}

const searchClient = algoliasearch(
  'DXOJAI9LZU', 'be158981e442b1f5b42269822051dd47'
);

const CustomSearchBox = connectSearchBox(({ refine }) => {
  const [value, setValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    refine(newValue);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    window.location.href = "/search?search=" + value; 
  };

  return (
    <form onSubmit={handleSubmit} style={{ position: 'relative' }}>
      <input
        type="search"
        value={value}
        onChange={handleInputChange}
        placeholder="Search for a post..."
        style={{ width: '20rem' }}
      />
      <button type="submit" css={CustomSearchBox_icon}>
        <SearchIcon />
      </button>
    </form>
  );
});

const Content = connectStateResults(
({ searchState, searchResults }) =>
  searchState && searchState.query && searchResults && searchResults.nbHits !== 0
  ? <div><Hits hitComponent={PostPreview} /><PoweredBy /></div>
  : searchState.query && searchResults && searchResults.nbHits < 1
  ? <div class="no-results">no results found for <span class="no-results-query">{searchState.query}</span></div>
  : <div></div>
)

class SiteNav extends React.Component<SiteNavProps> {

  render() {
    const { isHome = false } = this.props;
    return (
      <nav css={[isHome && HomeNavRaise, SiteNavStyles]}>
        <SiteNavLeft>
          <div css={SiteNavMenu}>
              <SiteNavLogo />
              <a css={SiteNavMenuItem} href="/">BLOG HOME</a>
              <a css={SiteNavMenuItem} href="/tags/m-365/">M365</a>
              <a css={SiteNavMenuItem} href="/tags/azure/">AZURE</a>
              <a css={SiteNavMenuItem} href="/tags/security/">SECURITY</a>
              <a css={SiteNavMenuItem} href="/tags/insights/">INSIGHTS</a>
              <a css={SiteNavMenuItem} href="/tags/videos/">VIDEOS</a>
              <a css={SiteNavMenuItem} href="https://www.trustedtechteam.com/">SHOP</a>
              <a css={SiteNavMenuItem} href="https://www.trustedtechteam.com/pages/about-us/">ABOUT</a>     
          </div>
        </SiteNavLeft>
        {/* <SiteNavCenter>
          <SiteNavLogo />
        </SiteNavCenter> */}
        <SiteNavRight>
            {/* <SocialLinks>
              {config.spiceworks && (
                <a
                  css={SocialLink}
                  href={config.spiceworks}
                  target="_blank"
                  title="Spiceworks"
                  rel="noopener noreferrer"
                >
                  <Spiceworks />
                </a>
              )}
              {config.facebook && (
                <a
                  css={SocialLink}
                  href={config.facebook}
                  target="_blank"
                  title="Facebook"
                  rel="noopener noreferrer"
                >
                  <Facebook />
                </a>
              )}
              {config.twitter && (
                <a
                  css={SocialLink}
                  href={config.twitter}
                  title="Twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter />
                </a>
              )}
              {config.linkedin && (
                <a
                  css={SocialLink}
                  href={config.linkedin}
                  title="LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn />
                </a>
              )}
              {config.youtube && (
                <a
                  css={SocialLink}
                  href={config.youtube}
                  title="YouTube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YouTube />
                </a>
              )}
            </SocialLinks> */}
          <div className="site-nav-instant-search-wrapper">
            <InstantSearch searchClient={searchClient} indexName="gatsby-blog-ttt">
              <CustomSearchBox />
              <Content />
            </InstantSearch>
          </div>
          <a target="_blank" href="https://www.trustedtechteam.com/pages/contact-us">
          <PostCTAbutton>Contact Us</PostCTAbutton>
          </a>
          <div css={HeaderDarkModeToggle}>
            <DarkModeToggle />
          </div>
        </SiteNavRight>
      </nav>
    );
  }
}

export default SiteNav;