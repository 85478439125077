import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { AuthorProfileImage, NavIcon } from '../styles/shared';
import HomeIcon from './icons/home';
import Newspaper from './icons/newspaper';
import Business from './icons/business';
import LightBulb from './icons/lightbulb';
import InfoCircle from './icons/info';
import Bolt from './icons/bolt';
import VideoIcon from './icons/video';
import Cart from './icons/cart';
import AzureIcon from './icons/azure';
import M365Icon from './icons/m365';
import SecurityIcon from './icons/security';
import Search from './icons/search';
import DarkModeToggle from '../components/darkmode/DarkModeToggle';
import '../styles/BurgerMenu.css';

const BurgerMenu = {
  bmBurgerButton: {
    position: 'fixed',
    // margin: '50px',
    width: '26px',
    height: '20px',
    // left: '50px',
    // top: '35px'
  },
  bmBurgerBars: {
    background: '#ffffff',
    transition: 'all 0.2s ease-in-out',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: 'var(--burgerBg)',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    display: 'flex',
    flexDirection: 'column',
  },
  bmItem: {
    display: 'flex',
    padding: '0.8em',
    color: 'var(--textNormal)',
    alignItems: 'center',
    outline: 'none',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

class Burger extends React.Component {
  showSettings(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="burgerMenuWrapper">
        <Menu noTransition disableAutoFocus styles={BurgerMenu}>
          <a css={NavIcon} id="home" className="menu-item" href="/">
            <HomeIcon />
            <span>&nbsp;BLOG HOME</span>
          </a>
          <a css={NavIcon} id="m-365" className="menu-item" href="/tags/m-365/">
            <M365Icon />
            <span>&nbsp;M365</span>
          </a>
          <a css={NavIcon} id="azure" className="menu-item" href="/tags/azure/">
            <AzureIcon />
            <span>&nbsp;AZURE</span>
          </a>
          <a css={NavIcon} id="security" className="menu-item" href="/tags/security/">
            <SecurityIcon />
            <span>&nbsp;SECURITY</span>
          </a>
          <a css={NavIcon} id="insights" className="menu-item" href="/tags/insights/">
            <Newspaper />
            <span>&nbsp;INSIGHTS</span>
          </a>
          {/* <a css={NavIcon} id="business-spotlight" className="menu-item" href="/tags/business-spotlight"><Business/><span>&nbsp;SPOTLIGHT</span></a> */}
          <a css={NavIcon} id="Videos" className="menu-item" href="/tags/videos/">
            <VideoIcon />
            &nbsp;VIDEOS
          </a>
          <a css={NavIcon} id="shop" className="menu-item" href="https://www.trustedtechteam.com/">
            <Cart />
            &nbsp;SHOP
          </a>
          <a css={NavIcon} id="about" className="menu-item" href="https://www.trustedtechteam.com/pages/about-us">
            <InfoCircle />
            <span>&nbsp;ABOUT</span>
          </a>
          <a css={NavIcon}>
            <DarkModeToggle />
          </a>
        </Menu>
      </div>
    );
  }
}

export default Burger;
