export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  /**
   * full url, no username
   */
  linkedin?:string;
  /**
   * full url, no username
   */
  youtube?:string;
  /**
   * full url, no username
   */
  spiceworks?:string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
   * mailchimp group code
   */
  mailchimpBlogGroup?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */

  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: 'Trusted Tech Team Blog',
  description: 'Your trusted source for all things tech. The Trusted Tech Team Blog provides thought leadership and insightful articles on popular and emerging technologies',
  coverImage: 'img/blog-cover.jpg',
  logo: 'img/ttt-blog_banner.png',
  lang: 'en',
  siteUrl: 'https://blog.trustedtechteam.com/',
  facebook: 'https://www.facebook.com/trustedtechteam',
  twitter: 'https://twitter.com/trustedtechteam',
  linkedin: 'https://www.linkedin.com/company/trustedtechteam',
  youtube: 'https://www.youtube.com/channel/UCLbJwmhn2cN-_ZaoqeHLGpg',
  spiceworks: 'https://community.spiceworks.com/pages/trustedtechteam',
  showSubscribe: true,
  mailchimpAction: 'https://trustedtechteam.us14.list-manage.com/subscribe/post?u=b56ce28074d3ee676201437ad&amp;id=8ac6251e24',
  mailchimpName: 'b_b56ce28074d3ee676201437ad_8ac6251e24',
  mailchimpBlogGroup: 'group[8984][2]',
  mailchimpEmailFieldName: 'MERGE0',
  googleSiteVerification: 'GoogleCode',
  footer: 'your trusted source for all things tech.',
};

export default config;
