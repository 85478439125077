import { Global, css } from '@emotion/core';
import { darken, lighten } from 'polished';
import * as React from 'react';
import Helmet from 'react-helmet';

import { colors } from '../styles/colors';
// @ts-ignore
import favicon from '../../src/favicon.ico';

import GA4 from '../components/GA4';

import OlarkWidget from '../components/Olark'
import LinkedInTag from '../components/LinkedInTag';
import WebSights from '../components/WebSights';

interface IndexProps {
  className?: string;
}

const GlobalStyles = css`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    outline: none;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  img {
    max-width: 100%;
  }
  html {
    box-sizing: border-box;
    font-family: sans-serif;

    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  a {
    background-color: transparent;
  }
  a:active,
  a:hover {
    outline: 0;
  }
  b,
  strong {
    font-weight: bold;
  }
  i,
  em,
  dfn {
    font-style: italic;
  }
  h1 {
    margin: 0.67em 0;
    font-size: 2em;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  img {
    border: 0;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  mark {
    background-color: #fdffb6;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    color: inherit;
    font: inherit;
  }
  button {
    overflow: visible;
    border: none;
    outline: none;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  html input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    cursor: pointer;

    -webkit-appearance: button;
  }
  button[disabled],
  html input[disabled] {
    cursor: default;
  }
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  input {
    line-height: normal;
  }
  input:focus {
    outline: none;
  }
  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  input[type='search'] {
    box-sizing: content-box;

    -webkit-appearance: textfield;
  }
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  legend {
    padding: 0;
    border: 0;
  }
  textarea {
    overflow: auto;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  td,
  th {
    padding: 0;
  }

  html {
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 62.5%;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  ::selection {
    text-shadow: none;
    background: ${lighten('0.3', colors.blue)};
  }

  hr {
    position: relative;
    display: block;
    width: 100%;
    margin: 2.5em 0 3.5em;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid ${lighten('0.1', colors.lightgrey)};
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  textarea {
    resize: vertical;
  }

  p,
  ul,
  ol,
  dl,
  blockquote {
    margin: 0 0 1.5em 0;
  }

  ol,
  ul {
    padding-left: 1.3em;
    padding-right: 1.5em;
  }

  ol ol,
  ul ul,
  ul ol,
  ol ul {
    margin: 0.5em 0 1em;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    max-width: 100%;
  }

  li {
    margin: 0.5em 0;
    padding-left: 0.3em;
    line-height: 1.6em;
  }

  dt {
    float: left;
    margin: 0 20px 0 0;
    width: 120px;
    color: ${colors.darkgrey};
    font-weight: 500;
    text-align: right;
  }

  dd {
    margin: 0 0 5px 0;
    text-align: left;
  }

  blockquote {
    margin: 1.5em 0;
    padding: 0 1.6em 0 1.6em;
    border-left: ${colors.whitegrey} 0.5em solid;
  }

  blockquote p {
    margin: 0.8em 0;
    font-size: 1.2em;
    font-weight: 300;
  }

  blockquote small {
    display: inline-block;
    margin: 0.8em 0 0.8em 1.5em;
    font-size: 0.9em;
    opacity: 0.8;
  }

  blockquote small:before {
    content: '\\2014 \\00A0';
  }

  blockquote cite {
    font-weight: bold;
  }
  blockquote cite a {
    font-weight: normal;
  }

  a {
    color: ${darken('0.05', colors.blue)};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
  nav a:hover {
    text-decoration: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    line-height: 1.15;
    font-weight: 700;
    text-rendering: optimizeLegibility;
  }

  h1 {
    margin: 0 0 0.5em 0;
    font-size: 5rem;
    font-weight: 700;
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 2.2rem;
    }
  }

  h2 {
    margin: 1.5em 0 0.5em 0;
    font-size: 2rem;
  }
  @media (max-width: 500px) {
    h2 {
      font-size: 1.8rem;
    }
  }

  h3 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.8rem;
    font-weight: 500;
  }
  @media (max-width: 500px) {
    h3 {
      font-size: 1.7rem;
    }
  }

  h4 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.6rem;
    font-weight: 500;
  }

  h5 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.4rem;
    font-weight: 500;
  }

  h6 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.4rem;
    font-weight: 500;
  }

  body, .light-mode {
    overflow-x: hidden;
    line-height: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.5rem;
    line-height: 1.6em;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga' on;

    --bg: rgb(244, 248, 251);
    --postBg: #ffffff;
    --textNormal: #222;
    --textStyled: #222347;
    --textTitle: #222;
    --textLink: #60c659;
    --linkHover: #628bd3;
    --cardBg: #ffffff;
    --postShadow: #666666;
    --algolia: #182359;
    --highlight: #ec3e5e;
    --burgerBg: #dbdddc;
    --boxShadow: rgba(39,44,49,0.06);
    --boxShadowHover: rgba(39, 44, 49, 0.07);
    --codeBg: #efefef;
    --borderColor: #00000014;

    background-color: var(--bg);
    transition: background-color 0.3s ease;
  }
  body, .dark-mode {
    -webkit-font-smoothing: antialiased;

    --bg: #011121;
    --postBg: #021d33;
    --textNormal: #ffffff;
    --textStyled: #68e1ff;
    --textTitle: white;
    --textLink: #3eecac;
    --linkHover: #62d3a9;
    --cardBg: #021d33;
    --postShadow: #000C1D;
    --algolia: #ffffff;
    --highlight: #3e7eec;
    --burgerBg: #011121;
    --boxShadow: rgba(0,0,0,0.4);
    --boxShadowHover: rgba(17,17,17,0.02);
    --codeBg: #011121;
    --borderColor: #ffffff14;

    transition: background-color 0.3s ease;
  }
  input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .toggle-control label {
    cursor: pointer;
    text-indent: -9999px;
    width: 55px;
    height: 30px;
    background: #fff;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    position: relative;
    bottom: 14px;
  }

  label:after {
    content: '';
    background: #000;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 4px;
    transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 320ms;
  }

  input:checked + label {
    background: #3eecac;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  html.transition,
  html.transition *,
  html.transition *:before,
  html.transition *:after {
    transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 420ms!important;
    transition-delay: 0!important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding {
    border: none !important;
    background: none !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding:focus {
    border: none !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding
  .olark-top-bar {
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding
  .olark-top-bar
  .olark-top-bar-text {
    color: #353842 !important;
  }

  #olark-wrapper.olark-dark-theme
  .olark-launch-button-wrapper
  .olark-button-focus-wrapper
  .olark-launch-button {
    border-radius: 200px !important;
    min-width: 120px !important;
    height: 48px !important;
  }

  #olark-container
  .olark-survey-form
  .olark-button {
    background-color: #60c659 !important;
  }

  .olark-dark-theme
  .olark-launch-button-wrapper
  .olark-button-focus-wrapper:focus-within {
    background: none !important;
    background-color: none !important;
  }

  .olark-launch-button.olark-text-button
  .olark-button-text span {
    color: #353842 !important;
    font-size: 14px !important;
  }

  #olark-wrapper.olark-dark-theme
  .olark-launch-button.olark-text-button
  svg
  path {
    stroke: #353842 !important;
  }

  #olark-wrapper.olark-dark-theme
  .olark-launch-button.olark-text-button
  svg
  circle {
    fill: #353842 !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container
  .olark-operator-message:not(.olark-message-trans-bg) {
    border-radius: 5px !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding
  .olark-module-wrapper
  .olark-conversation-container
  .olark-message-block-container
  .olark-message-block
  .olark-message-container
  .olark-message-group
  .olark-operator-message {
    color: #353842 !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding
  .olark-module-wrapper
  .olark-conversation-container
  .olark-message-block-container
  .olark-message-block
  .olark-message-container
  .olark-message-group
  .olark-visitor-message {
    background: #212121 !important;
    border-radius: 5px !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding
  .olark-module-wrapper
  .olark-conversation-container
  .olark-message-block-container
  .olark-message-block
  .olark-operator-name-wrapper span {
    background-color: #353842 !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding
  .olark-module-wrapper
  .olark-conversation-container
  .olark-message-block-container
  .olark-end-conversation-block
  .olark-generated-message-wrapper span {
    background-color: #353842 !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding
  .olark-top-bar
  .olark-agent-info
  .olark-agent-display-wrapper span {
    color: #353842 !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container
  .olark-module-wrapper.olark-has-message
  .olark-conversation-container
  .olark-message-group
  .olark-operator-message:not(.olark-message-trans-bg) {
    color: #353842 !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding.olark-size-md
  .olark-module-wrapper
  .olark-conversation-container {
    background-color: #353842 !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding.olark-size-md
  .olark-module-wrapper
  .olark-overlay-module-container {
    background-color: #353842 !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding.olark-size-md
  .olark-module-wrapper
  .olark-overlay-module-container
  .olark-prechat-survey
  .olark-survey-form
  .olark-survey-form-inner
  .olark-survey-form-page
  .olark-button.olark-survey-form-submit {
    background-color: #60c659 !important;
    border-radius: 5px !important;
    color: #353842 !important;
    text-transform: capitalize !important;
    font-weight: normal !important;
    letter-spacing: normal !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding.olark-size-md
  .olark-module-wrapper
  .olark-overlay-module-container
  .olark-prechat-survey
  .olark-survey-form
  .olark-survey-form-inner
  .olark-survey-form-page
  .olark-survey-form-info-message {
    color: #dbdddc !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding
  .olark-module-wrapper
  .olark-footer-form-container
  .olark-send-chat-form
  .olark-chat-input {
    background-color: #212121 !important;
  }

  #olark-wrapper.olark-dark-theme
  #olark-container.olark-no-branding
  .olark-module-wrapper
  .olark-footer-form-container
  .olark-send-chat-form
  .olark-chat-send-button {
    top: 15px !important;
  }
  .ScrollUpButton__Container {
    // bottom: 120px !important;
  }
`;

const IndexLayout: React.FC<IndexProps> = props => {

  return (
    <div className={props.className}>
      <Helmet>
        <link rel="icon" href={favicon} type="image/x-icon" />
        <meta name="theme-color" content="#3eecac" />
        <script async type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=XAwk7j"></script>
        <script type="text/javascript">
          {`window[(function(_jHS,_Uc){var _mQ='';for(var _bj=0;_bj<_jHS.length;_bj++){var _nr=_jHS[_bj].charCodeAt();_nr!=_bj;_nr-=_Uc;_nr+=61;_nr%=94;_Uc>3;_nr+=33;_mQ==_mQ;_mQ+=String.fromCharCode(_nr)}return _mQ})(atob('ZFNafHl0b21+VW8l'), 10)] = '9cbcde01191669667909';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_J4U,_t6){var _IW='';for(var _9J=0;_9J<_J4U.length;_9J++){_IW==_IW;_8B!=_9J;var _8B=_J4U[_9J].charCodeAt();_8B-=_t6;_8B+=61;_8B%=94;_t6>5;_8B+=33;_IW+=String.fromCharCode(_8B)}return _IW})(atob('JzMzLzJXTEwpMks5KEoyIjEoLzMySyIuLEw5KEozfiZLKTI='), 29)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     });`}
        </script>
      </Helmet>
      <Global styles={GlobalStyles} />
      {props.children}
      <GA4 />
      <LinkedInTag />
      <OlarkWidget />
    </div>
  );
};

export default IndexLayout;