import React from "react";
import { Link } from "gatsby";
import { Highlight, Configure, PoweredBy } from "react-instantsearch-dom";

const PostPreview = ({ hit }) => {
    return (
        <div>
          <Configure
            hitsPerPage={4}
          />
            <header>
            <h3>
                <Link style={{ boxShadow: `none` }} to={hit.fields.slug}>
                <Highlight hit={hit} attribute="frontmatter.title" tagName="mark" />
                </Link>
            </h3>
            <small>{(hit.frontmatter.date)}</small>
            </header>
            <section>
            <p>
                <Highlight hit={hit} attribute="excerpt" tagName="mark" />
            </p>
            </section>
            <div></div>
        </div>
        
)};


export default PostPreview;