import React from 'react';
import InnerHTML from 'dangerously-set-html-content';

function GA4() {
  const html = `
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-C0TC0PN93N"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'G-C0TC0PN93N');
    </script>
  `
  return (
    <InnerHTML html={html} />
  )
}

export default GA4;