import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from './colors';
import { darken, lighten } from 'polished';

export const SiteNavMenu = css`
  display: flex;
  @media(max-width: 1571px){
    display: none;
  }
`;

export const SiteNavMenuItem = css`
display: flex;
justify-content: center;
align-items: center;
margin: 0;
padding: 0px 15px;
color: rgba(255,255,255,0.75);
font-family: 'Rubik';
font-weight: 400;
font-size: 1.563rem;
letter-spacing: 1px;
transition: 0.4s;

:hover {
  text-decoration: underline;
  text-underline-position: under;
  color: #3eecac;
}
`;

export const outer = css`
  position: relative;
  padding: 0 .5vw;
`;

// Centered content container blocks
export const inner = css`
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
  @media(max-width: 500px) {
    padding: 0 10px 0 10px;
  }
`;

export const SiteMain = css`
  z-index: 1;
  flex-grow: 1;
`;

export const SiteTitleContainer = styled.div`
  font-family: 'Rubik';
  font-weight: 700;
  text-transform: uppercase;
  z-index: 10;
  margin: 0;
  padding: 0;
  font-size: 3.8rem;
`;

export const SiteTitle = styled.h1`
  font-family: 'Rubik';
  font-weight: 700;
  text-transform: uppercase;
  z-index: 10;
  margin: 0;
  padding: 0;
  font-size: 3.8rem;
`;

export const SiteTitleHidden = styled.h1`
  position: absolute;
  z-index: -9999999999;
  top: -9999999999px;
  left: -9999999999px;
`;

export const SiteDescription = styled.h2`
  font-family: 'Rubik';
  z-index: 10;
  margin: 0;
  margin-top: 2rem;
  padding: 5px 0;
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  opacity: 1;
  @media (max-width: 400px) {
    font-size: 2rem;
  }
`;

export const PostFeed = css`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 40px 0 0 0;
`;

export const PostFeedRaise = css`
  @media (min-width: 900px) {
    margin-top: -70px;
    padding-top: 0;
  }
`;

export const SocialLink = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px;
  color: #fff;
  opacity: 0.8;
  transition: 0.1s;

  :hover {
    color: #3eecac;
    transform: scale(1.2);
  }

  svg {
    height: 1.8rem;
    fill: #fff;
  }
`;

export const SearchWidgetIcon = css`
display: flex;
position: fixed;
right: 24rem;
justify-content: center;
align-items: center;
margin: 0;
padding: 10px;
color: var(--textLink);
opacity: 0.8;
z-index: -1;

svg {
  height: 1.5rem;
  fill: var(--textLink);
}
@media(max-width: 1480px) {
  right: 17.5rem;
}
@media(max-width: 768px) {
  right: 14.5rem;
}
`

export const IndexSiteHeader = css`
  position: relative;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
  /* background: color(var(--darkgrey) l(-5%)) no-repeat center center; */
  background: ${darken('0.05', colors.darkgrey)} no-repeat center center;
  background-size: cover;
  // Add gradient to Homepage Image (for later implementation)
  // -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  // mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
  margin-top: 20px;
  @media (max-width: 700px) {
  // padding-bottom: 0;
  }
`

export const SiteHeader = css`
  position: fixed;
  z-index: 999;
  width: 100%;
  padding: 25px 50px;
  color: #fff;
  /* background: color(var(--darkgrey) l(-5%)) no-repeat center center; */
  background: #0C0D09;
  background-size: cover;
  @media (max-width: 768px) {
    padding: 15px 20px;
  }
  // @media (max-width: 700px) {
  //   padding-bottom: 0px;
  // }
  @media (max-width: 600px) {
    img {
      padding: 4vw 10vw 0 10vw;
  }
`;

export const SiteHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10vw 4vw;
  min-height: 200px;
  max-height: 450px;
  text-align: center;
  @media (max-width: 1200px) {
    padding: 10vw 0;
  }
`;

export const SiteHeaderStyles = css`
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 10;
  display: block;
  height: 80px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));

  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: rgba(0, 0, 0, 0.18);
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    display: block;
    height: 80px;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  }
  @media (max-width: 700px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const HomeNavRaise = css`
  @media (min-width: 900px) {
    position: relative;
    top: -70px;
  }
`;

export const TagsHero = css`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
  background: ${darken('0.05', colors.darkgrey)} no-repeat center center; 
  background-size: cover;
  @media (max-width: 700px) {
  padding-bottom: 0;
  margin-top: 60px;
  }
`

export const AuthorProfileImage = css`
  display: block;
  /* background: color(var(--lightgrey) l(+10%)); */
  /* background: ${lighten('0.1', colors.lightgrey)}; */
  border-radius: 100%;
  object-fit: cover;
  margin-right: 15px;
  width: 60px;
  height: 60px;
  @media (max-width: 700px) {
    margin-top: 14vw;
  }
`;

export const CommentBox = styled.section`
  .commento-root {
    padding: 70px 100px 0;
  }
  @media (max-width: 700px) {
    .commento-root {
      padding: 0;
    }
  }
  .commento-root
  .commento-login
  .commento-login-text {
    color: var(--textLink);
  }
  .commento-root
  .commento-card {
    border-top: 1px solid var(--textLink);
  }
  .commento-root * {
    color: var(--textNormal);
  }
  .commento-root
  .commento-sort-policy-buttons-container
  .commento-sort-policy-buttons
  .commento-sort-policy-button {
    color: var(--textNormal);
  }
  .commento-root
  .commento-sort-policy-buttons-container
  .commento-sort-policy-buttons
  .commento-sort-policy-button-selected {
    color: #3eb0ef;
  }
  .commento-root 
  .commento-card 
  .commento-name {
    color: var(--textStyled);
  }
  .commento-root
  .commento-card
  .commento-option-collapse {
    background: #A1250A;
  }
  .commento-root
  .commento-card
  .commento-score
    {
    color: var(--textNormal);
  }
  .commento-root
  .commento-card
  .commento-timeago {
    color: var(--textNormal);
  }
  .commento-root
  .commento-footer
  .commento-logo-container
  .commento-logo-text {
    color: var(--textNormal);
  }
  .commento-root input[type="text"], .commento-root textarea {
    color: #525f7f;
  }
  .commento-root
  .commento-logged-container
  .commento-logged-in-as
  .commento-name {
    color: var(--textStyled);
  }
`

export const NavIcon = css`
  font-family: 'Rubik';
  font-weight: 400;
  display: flex;
  justify-content: left;
  align-items: left;
  vertical-align: middle; 
  margin: 0;
  padding: 10px;
  color: #fff;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: #fff;
  }
  :last-child {
    margin-top: auto;
  }
`;