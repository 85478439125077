import React from 'react';
import Toggle from './Toggle';
import useDarkMode from 'use-dark-mode';

const DarkModeToggle = () => {

  const darkMode = useDarkMode(true)

  return (
    <div className="dark-mode-toggle">
      <Toggle checked={darkMode.value} onClick={darkMode.toggle} />
    </div>
  );
}; 

export default DarkModeToggle;
